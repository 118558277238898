import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UserService } from '@core/services';
import { environment } from '@environments/environment';
import { finalize, BehaviorSubject, tap, catchError, EMPTY, throwError } from 'rxjs';
import { Ticket } from '@core/models';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-zendesk-tickets-table',
  templateUrl: './zendesk-tickets-table.component.html',
  styleUrls: ['./zendesk-tickets-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZendeskTicketsTableComponent implements OnInit {
  minNumberOfRows = 7;
  emptyRows: Ticket[] = Array(this.minNumberOfRows).fill({});
  displayedColumns: string[] = ['title', 'number', 'lastUploaded', 'status'];
  isLoading = true;
  dataSource = new BehaviorSubject<Ticket[]>(this.emptyRows); // Initialize
  zendeskAgent: boolean | undefined; // To store the value
  constructor(private readonly userService: UserService) {
    this.emptyRows[0] = { title: 'Loading...' };

    this.userService.getUserData().subscribe((userData) => {
      this.zendeskAgent = userData?.zendesk_agent;
    });
  }

  ngOnInit() {
    this.getUserTickets();
  }

  getUserTickets() {
    this.userService
      .getTickets()
      .pipe(
        finalize(() => (this.isLoading = false)),
        tap((tickets) => {
          let paddedTickets = this.emptyRows;
          paddedTickets[0] = { title: 'No Issues Found' };
          // Ensure at least 7 rows
          if (tickets.length) {
            paddedTickets = [
              ...tickets,
              ...this.emptyRows.reverse().slice(0, Math.max(this.minNumberOfRows - tickets.length, 0)),
            ];
          }
          this.dataSource.next(paddedTickets);
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status >= 400 && error.status < 500) {
            const error_message = this.emptyRows;
            error_message[0] = { title: 'Unable to display data. Please try again later.' };
            this.dataSource.next(error_message); // Display message
            return EMPTY; // Stop the observable chain
          } else {
            // Other error types
            console.error('An error occurred:', error);
            return throwError(() => error); // Re-throw for potential error handling elsewhere
          }
        }),
      )
      .subscribe();
  }

  createTicket() {
    window.location.href =
      environment.zendeskUrl + (this.zendeskAgent ? '/agent/tickets/new/1' : '/hc/en-us/requests/new');
  }

  protected readonly environment = environment;
}
