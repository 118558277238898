<app-user-mimic-status-bar></app-user-mimic-status-bar>
<header>
  <mat-toolbar>
    <a class="navbar-brand" href="/">
      <img src="assets/main_logo.png" alt="Logo" loading="lazy" class="brand-logo display-light" />
      <img src="assets/main_logo_dark.png" alt="Logo" loading="lazy" class="brand-logo display-dark" />
    </a>
    <div class="tools-panel">
      <app-tools-panel></app-tools-panel>
    </div>
    <button class="user-profile-panel" [matMenuTriggerFor]="menu">
      @if (userName()) {
        <!--
          <div class="user-picture"></div>
        -->
        <div class="user-info">
          <div class="user-name text-ellipsis">
            {{ userName() }}
          </div>
          <div class="user-organization text-ellipsis">
            {{ userOrganization() }}
          </div>
        </div>
      }
    </button>
    <mat-menu #menu="matMenu" classList="menu-shadow">
      <!--    <button mat-menu-item [routerLink]="['user']">Profile</button>-->
      <button mat-menu-item [routerLink]="['auth', 'logout']">Log Out</button>
    </mat-menu>
  </mat-toolbar>
</header>
<mat-drawer-container class="content-container">
  <mat-drawer mode="side" opened [disableClose]="true">
    <!-- todo: refactor organizations routing ones requirements are ready -->
    @if (isOrganizationsRoute()) {
      <app-menu-organizations></app-menu-organizations>
    } @else {
      <app-menu></app-menu>
    }
  </mat-drawer>
  <mat-drawer-content>
    <main>
      <router-outlet></router-outlet>
    </main>
  </mat-drawer-content>
</mat-drawer-container>

<app-idle></app-idle>
