import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DashboardModule } from '@features/dashboard';
import { MimicUserModule } from '@features/mimic-user';
import { SecurityModule } from '@features/security';
import { SharedModule } from '@shared/shared.module';
import {
  ErrorPageContentComponent,
  HomeComponent,
  MainComponent,
  MenuComponent,
  Page403Component,
  Page404Component,
  PageReloadComponent,
  PageUnknownErrorComponent,
  ToolsPanelComponent,
  UnAuthenticatedComponent,
} from './components';
import { ThemeService } from '../core/services/theme/theme.service';
import { OrganizationsModule } from '@features/organizations/organizations.module';

const components = [
  HomeComponent,
  MainComponent,
  ErrorPageContentComponent,
  Page403Component,
  Page404Component,
  PageReloadComponent,
  PageUnknownErrorComponent,
  ToolsPanelComponent,
  UnAuthenticatedComponent,
  MenuComponent,
  ToolsPanelComponent,
];

@NgModule({
  declarations: [...components],
  providers: [ThemeService],
  exports: [...components],
  imports: [
    CommonModule,
    RouterModule,
    SecurityModule,
    MimicUserModule,
    SharedModule,
    DashboardModule,
    OrganizationsModule,
  ],
})
export class LayoutModule {}
