import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ZendeskTicketsTableComponent } from './components/zendesk-tikets-table/zendesk-tickets-table.component';

@NgModule({
  declarations: [ZendeskTicketsTableComponent],
  exports: [ZendeskTicketsTableComponent],
  imports: [CommonModule, SharedModule],
})
export class DashboardModule {}
