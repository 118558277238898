<div class="p-2">
  <mat-list>
    <a mat-list-item [routerLink]="['/']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="menu-active">
      <div class="menu-item">
        <mat-icon>home</mat-icon>
        <div class="menu-item-text">Home</div>
      </div>
    </a>
    <a mat-list-item *accessView="['view_datasource']" [routerLink]="['/data-sources']" routerLinkActive="menu-active">
      <div class="menu-item">
        <mat-icon>database</mat-icon>
        <div class="menu-item-text">Data sources</div>
      </div>
    </a>
    <a mat-list-item *accessView="['view_connector']" [routerLink]="['/connectors']" routerLinkActive="menu-active">
      <div class="menu-item">
        <mat-icon>schema</mat-icon>
        <div class="menu-item-text">Connectors</div>
      </div>
    </a>
    <mat-list-item class="d-none">
      <div class="menu-item">
        <mat-icon>valve</mat-icon>
        <div class="menu-item-text">Pipelines</div>
      </div>
    </mat-list-item>
    <a
      mat-list-item
      *accessView="['view_vpnconfig']"
      [routerLink]="['/vpn-configuration']"
      routerLinkActive="menu-active"
    >
      <div class="menu-item">
        <mat-icon>settings</mat-icon>
        <div class="menu-item-text">VPN Configuration</div>
      </div>
    </a>
    <a mat-list-item *accessView="['view_user']" [routerLink]="['/user-management']" routerLinkActive="menu-active">
      <div class="menu-item">
        <mat-icon>manage_accounts</mat-icon>
        <div class="menu-item-text">User management</div>
      </div>
    </a>
    <a mat-list-item *accessView="['view_auditlog']" [routerLink]="['/audit-log']" routerLinkActive="menu-active">
      <div class="menu-item">
        <mat-icon>summarize</mat-icon>
        <div class="menu-item-text">Audit Log</div>
      </div>
    </a>
    <mat-list-item class="d-none">
      <div class="menu-item">
        <mat-icon>orders</mat-icon>
        <div class="menu-item-text">Order management</div>
      </div>
    </mat-list-item>
    <mat-list-item class="d-none">
      <div class="menu-item">
        <mat-icon>notifications_none</mat-icon>
        <div class="menu-item-text">Notifications</div>
      </div>
    </mat-list-item>
  </mat-list>
</div>
