import { Component, OnInit, signal } from '@angular/core';
import { ThemeService } from '@core/services';

@Component({
  selector: 'app-tools-panel',
  templateUrl: './tools-panel.component.html',
  styleUrl: './tools-panel.component.scss',
})
export class ToolsPanelComponent implements OnInit {
  isDarkTheme = signal<boolean>(false);

  constructor(private readonly themeService: ThemeService) {}

  ngOnInit(): void {
    this.isDarkTheme.set(this.themeService.isDarkTheme);
  }

  onToggle() {
    this.themeService.toggle();
    this.isDarkTheme.set(this.themeService.isDarkTheme);
  }
}
