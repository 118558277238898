import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { UserService } from '@core/services';
import { map } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent {
  isOrganizationsRoute = signal<boolean>(this.router.url.includes('organizations'));
  public userName = toSignal<string>(
    this.userService.getUserData().pipe(map((el): string => el?.name || '<user name>')),
  );
  public userOrganization = toSignal<string>(
    this.userService.getUserData().pipe(map((el) => el?.email || '<no organization>')),
  );

  constructor(
    private router: Router,
    private userService: UserService,
  ) {}

  public logout(): void {
    this.router.navigate(['/auth', 'logout']);
  }
}
