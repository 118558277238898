<!--<p>home works!</p>-->

<!--<button (click)="getUserDetails()">Get User Details</button>-->
<!--<button (click)="getUserTickets()">Get User Tickets</button>-->
<div class="container">
  <div class="row">
    <div class="col-md-8">
      <h1>Open Issues</h1>
    </div>
    <div class="col-md-4 d-flex justify-content-end align-items-center">
      <button type="button" class="btn btn-primary zendesk-button" (click)="createTicket()">+ New Issue</button>
    </div>
  </div>
</div>
<div class="table-content">
  <div *ngIf="isLoading">
    <mat-progress-bar mode="query"></mat-progress-bar>
  </div>
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>Subject</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.url; else plainTitle">
          <a
            class="table-link"
            [href]="
              zendeskAgent
                ? environment.zendeskUrl + '/agent/tickets/' + element.number
                : environment.zendeskUrl + '/hc/en-us/requests/' + element.number
            "
          >
            {{ element.title }}
          </a>
        </ng-container>
        <ng-template #plainTitle>
          {{ element.title }}
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element">{{ element.number }}</td>
    </ng-container>

    <ng-container matColumnDef="lastUploaded">
      <th mat-header-cell *matHeaderCellDef>Last activity</th>
      <td mat-cell *matCellDef="let element">{{ element.lastUploaded }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">
        <span class="status-ticket status-{{ element.status }}">{{ element.status | uppercase }}</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
