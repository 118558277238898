import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { LogService } from '@core/services';
import { InfoMessagesService } from '@features/info-messages';
import { catchError, throwError } from 'rxjs';

export const httpErrorInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const infoMessagesService = inject(InfoMessagesService);
  const log = inject(LogService);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      switch (error.status) {
        case 0:
          infoMessagesService.showError('API connection error');
          break;
        case HttpStatusCode.NotFound:
        case HttpStatusCode.BadRequest:
          break;
        default:
          log.warn('Unhandled HTTP error: ', error);
      }
      return throwError(() => error);
    }),
  );
};
