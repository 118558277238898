<div class="col-auto side-space d-none d-xl-block"></div>
<div class="col-full seatch-panel">
  <mat-form-field class="input-normalize" *accessView="['view_search']">
    <input type="search" matInput placeholder="Search" disabled="disabled" />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>
<div class="col-auto d-flex side-space">
  <button mat-icon-button disabled="disabled" class="tools-button" *accessView="['view_notifications']">
    <mat-icon>notifications_none</mat-icon>
  </button>
  <button mat-icon-button class="tools-button" (click)="onToggle()">
    @if (isDarkTheme()) {
      <mat-icon>sunny</mat-icon>
    } @else {
      <mat-icon>dark_mode</mat-icon>
    }
  </button>
  <button mat-icon-button class="tools-button" disabled="disabled">
    <mat-icon>headset_mic</mat-icon>
  </button>
  <button mat-icon-button class="tools-button" disabled="disabled">
    <mat-icon>ac_unit</mat-icon>
  </button>
</div>
