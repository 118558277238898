import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { IdleService, ThemeService, UserService } from '@core/services';
import { ServerSideAuthenticationModule, jwtInterceptor } from '@features/server-side-authentication';
import { EnsureModuleLoadedOnceGuard } from './ensure-module-loaded-once-guard';
import { httpErrorInterceptor } from './interceptors/http-error.interceptor';

@NgModule({
  declarations: [],
  imports: [CommonModule, ServerSideAuthenticationModule],
  providers: [
    provideHttpClient(withInterceptors([jwtInterceptor, httpErrorInterceptor])),
    IdleService,
    UserService,
    ThemeService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
